<template>
  <div style="padding-top:15x;padding-bottom:200px;">
    <div v-for="(item,i) in disributor.data" :key="i">
      <div style="font-size:15px;padding: 12px 15px " v-if="item.type === 0">
        <div style="display:flex;font-weight:700;">
          <div style="color:#474747;width:80%;display:flex;">{{ item.invitee_nickname }}
            <div style="margin-left:20px;color:#ec8938" v-if="!item.status">待确认</div>
          </div>

          <div style="color:#7dd97d;font-weight:700;width:20%;text-align: end;font-size:16px;">+{{ item.income }}</div>
        </div>
        <div class="time" style="margin-top:8px;color:#b7b7b7;font-size:13px;">{{ getLocalTime(item.timestamp) }}</div>
      </div>

      <div style="font-size:15px;padding: 12px 15px " v-if="item.type === 1">
        <div style="display:flex;font-weight:700;">
          <div style="color:#474747;width:80%;">支取金额</div>
          <div style="color:red;font-weight:700;width:20%;text-align: end;font-size:16px;">-{{ item.amount }}</div>
        </div>
        <div class="time" style="margin-top:8px;color:#b7b7b7;font-size:13px;">{{ getLocalTime(item.timestamp) }}</div>
      </div>
    </div>

  </div>
</template>

<script>
import {getDistributorDetails} from '../api/distributor'

export default {
  data() {
    return {
      disributor: {
        "data": []
      },
      unionid: "",
    }
  },
  computed: {},
  mounted() {
    document.title = '明细'
  },
  async created() {
    this.unionid = this.$route.query.unionid
    const res = await getDistributorDetails({unionid: this.unionid})
    console.log(res);
    this.disributor = res.data
  },
  methods: {
    getLocalTime(nS) {
      return new Date(parseInt(nS) * 1000).toLocaleString().replace(/:\d{1,2}$/, ' ');
    }
  }

}
</script>

<style scoped>
</style>